// import _ from 'lodash';
// window._ = _;

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

// import axios from 'axios';
// window.axios = axios;

// window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';


/**
 * Jquery
 */
import $ from 'jquery';
window.$ = $;
window.jQuery = $;


/**
 * Uikit
 */
import UIkit from 'uikit';
window.UIkit = UIkit;


/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
*/
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
window.Pusher = Pusher;
window.Echo = Echo;

/**
 * Alpine js
 */
import Alpine from 'alpinejs';
import focus from '@alpinejs/focus';
window.AlpineBoot = Alpine;
window.AlpineFocus = focus;
